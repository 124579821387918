import React, {StrictMode, useEffect, useState} from 'react';
import axios from 'axios';
import {authSteps} from "./authSteps";

function Error(props) {

    const [errors, setErrors] = useState([])

    useEffect(
        () => {
            if (props.errors.length > 0) {
                if (props.errors[0].CODE !== undefined) {
                    setErrors([{
                        title: props.errors[0].CODE,
                        text: props.errors[0].TEXT,
                    }])
                } else {
                    switch (props.errors[0]) {
                        case 'TIME_EXPIRED':
                            setErrors([{
                                title: 'Время действия кода истекло',
                                text: 'Код больше не действителен. Запросите код повторно.',
                            }])
                            break;
                        case 'PHONE_EXISTS':
                            // props.setStep(authSteps.STATE_PHONE_EXISTS);
                            setErrors([{
                                title: 'Номер телефона занят',
                                text: 'К сожалению, этот номер телефона уже используется',
                            }])
                            break;
                        default:
                            // props.setStep(authSteps.STATE_ERROR);
                            setErrors([{
                                title: 'Ошибка!',
                                text: props.errors[0].replaceAll("<br>", "\\n"),
                            }])
                    }
                }
            } else {
                setErrors([])
            }
        }, [props?.errors])

    if (errors.length === 0) {
        return null;
    }

    return (
        <div className="flex-col">
            <div className="form-group mb-5">
                <label className=" text-xs font-normal text-red-600">
                    {errors[0]['text']}
                </label>
            </div>
        </div>

    );
}

export default Error;