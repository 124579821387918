import React, {StrictMode, useEffect, useState} from 'react';
import axios from 'axios';
import {authSteps} from "./authSteps";
import TimeEscaped from "./TimeEscaped";

function CodeWaiting(props) {
    const [visible, setVisible] = useState(false)

    useEffect(() => {

        if (props.step === authSteps.STATE_CODE_WAITING || props.step === authSteps.STATE_CODE_REUSED) {
            setVisible(true)
        } else {
            setVisible(false)
        }
    }, [props?.step]);

    useEffect(() => {
        if (props.rootForm.current === undefined) {
            return
        }
        //TODO заменить на библиотеку из npm
        const node = document.querySelector('.auth_code')
        $(node).val('')
        const mask = Array(props.codeLength).fill("9").join(" ");
        $(node).inputmask(mask, {
            oncomplete: function () {
                let codeVal = $(node).val();
                codeVal = codeVal.replace(/\s/g, '');
                $(node).val(codeVal);

                // setCode(codeVal);

                sendGetCode();
            },
            clearIncomplete: false,
            autoUnmask: true
        });
    }, [visible]);

    const sendGetCode = () => {
        let data;
        if (props.rootForm.current) {
            data = Object.fromEntries(new FormData(props.rootForm.current.querySelector("#ctweb-smsauth_form_phone")));
            data.is_ajax_post = 'Y'
            data.PROVIDER_TYPE = props.type
            data.PHONE = data.PHONE.replace(/\D/g, "").substr(data.__phone_prefix.length)
            data.CODE = data.CODE.replace(/\s/g, "")
            if (props.isRegister) {
                let data_register = Object.fromEntries(new FormData(document.querySelector("#bx_2930803142__form")));
                data = {...data_register, ...data}
            }

            if (window.recaptcha !== undefined) {
                data.recaptcha_token = window.recaptcha.getToken()
            }
        }
        axios.post('/local/components/ctweb/sms.authorize/ajax.php',
            data
            , {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                }
            }
        ).then(response => {
            props.setStep(response.data.STEP);
            if (response.data.STEP === authSteps.STATE_SUCCESS) {
                window.location.reload();
                return
            }
            props.setReuseTime(response.data.REUSE_TIME)

            if (response.data.ERRORS.length > 0) {
                props.setErrors(response.data.ERRORS);
            } else {
                props.setErrors([])
            }
        })
    }

    return (
        <div className={"flex-col justify-center items-center mb-3 " + (visible ? 'block' : 'hidden')}>
            <div className="max-w-xs flex flex-col items-center justify-center w-full">

                <div className="form-group flex flex-col">
                    <div className="flex flex-row justify-between">
                        <label className="font-medium mb-2 text-textLight
                                        dark:text-white text-base"
                               for="sms-auth-code">{/*<?= GetMessage("SMS_AUTH_CODE") ?>*/}Введите код</label>
                        <a className="font-medium mb-2 text-textLight
                                        dark:text-white underline text-xs"
                           onClick={props.onChangePhone}>
                            {/*<?= GetMessage("SMS_AUTH_CHANGE_NUMBER_PHONE") ?>*/}Изменить номер</a>
                    </div>
                    <input type="text" name="CODE" id="bx_934924412_code"
                           className="form-control bg-textDark p-3 mb-2 dark:bg-grayButton cursor-pointer
                                                    w-full text-textLight rounded-md text-center
                                                    dark:text-white border-0 text-xl auth_code" autocomplete="off"/>
                    <span id="result"></span>
                </div>
                <TimeEscaped onEndTimer={props.onEndTimer} step={props.step} reuseTime={props.reuseTime}/>
            </div>
        </div>
    );
}

export default CodeWaiting;